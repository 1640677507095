<template>
	<div class="setting_page">
		<div class="top_title"><img src="@/assets/img/setting/setting.png" alt="" />设置中心</div>
		<div class="page_body">
			<div class="left_nav">
				<a-anchor :affix="false" :wrapperStyle="{ width: '280px', borderRadius: '4px' }">
					<a-anchor-link :href="'#' + item.id" v-for="item in navList" :key="item.id">
						<div slot="title" class="list_top_left"><img :src="item.icon" alt="" class="img" />{{ item.title }}</div></a-anchor-link
					>
				</a-anchor>
			</div>

			<!-- 右侧 -->
			<div class="right_content">
				<div class="title">检查版本</div>
				<div class="setting_list">
					<div class="setting_item">
						<div class="item_left">版本号V2.0.2.2</div>
						<div class="item_right">升级检测</div>
					</div>
				</div>
				<div class="title">设备信息</div>
				<div class="setting_list">
					<div class="setting_item">
						<div class="item_left">
							<div class="setting_item_title">设备识别码</div>
							<div class="setting_item_desc">58774A30-4321-5570-90A8-8FB4D7BB</div>
						</div>
						<div class="item_right">复制</div>
					</div>
					<div class="setting_item">
						<div class="item_left">
							<div class="setting_item_title">MAC地址</div>
							<div class="setting_item_desc">58774A30-4321-5570-90A8-8FB4D7BB</div>
						</div>
						<div class="item_right">复制</div>
					</div>
				</div>

				<div class="title">内存模式</div>
				<!-- 单选 -->
				<a-radio-group v-model="value" class="setting_list">
					<div class="setting_item">
						<div class="item_left">
							<div class="setting_item_title">极简模式</div>
							<div class="setting_item_desc">同时启动多个店铺或操作多个软件的情况下，建议使用此模式</div>
						</div>
						<a-radio :value="1"></a-radio>
					</div>
					<div class="setting_item">
						<div class="item_left">
							<div class="setting_item_title">均衡模式</div>
							<div class="setting_item_desc">介于极简与极速之间，适用于大多数场景</div>
						</div>
						<a-radio :value="2"></a-radio>
					</div>
					<div class="setting_item">
						<div class="item_left">
							<div class="setting_item_title">极速模式</div>
							<div class="setting_item_desc">每次启动1-2个店铺的情况下，建议使用此模式</div>
						</div>
						<a-radio :value="3"></a-radio>
					</div>
				</a-radio-group>
				<div class="title">系统通知</div>
				<div class="setting_list">
					<div class="setting_item">
						<div class="item_left">系统通知</div>
						<a-switch v-model="isNotify" />
					</div>
				</div>

				<div class="title">语言</div>
				<div class="setting_list">
					<div class="setting_item">
						<div class="item_left">中文简体</div>
						<div class="item_right">升级检测</div>
					</div>
					<div class="setting_item">
						<div class="item_left">English</div>
						<div class="item_right">升级检测</div>
					</div>
				</div>

				<div class="title">账号保存位置</div>
				<div class="setting_list">
					<div class="setting_item">
						<div class="item_left">C:\Users\Administrator\Desktop</div>
						<div class="item_right">变更</div>
					</div>
				</div>

				<div class="title">缓存管理</div>
				<div class="setting_list">
					<div class="setting_item">
						<div class="item_left">C:\Users\Administrator\AppData\Local\yangtaobrowser\UserData</div>
						<div class="item_right">变更</div>
					</div>
					<div class="setting_item">
						<div class="item_left">定期进行<span>文件清理</span>可以有效节省硬盘空间</div>
						<div class="item_right">清除</div>
					</div>
				</div>

				<div class="title">服务协议</div>
				<div class="setting_list">
					<div class="setting_item">
						<div class="item_left">快洋淘浏览器 《服务条款》</div>
					</div>
				</div>

				<div class="title">偏好设置</div>
				<div class="setting_list">
					<div class="setting_item">
						<div class="item_left">快洋淘浏览器专业版还不是您的默认浏览器</div>
						<div class="item_right">点击设置</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			navList: [
				{
					title: "检查版本",
					id: "edition",
					icon: require("@/assets/img/setting/edition.png"),
				},
				{
					title: "设备信息",
					id: "info",
					icon: require("@/assets/img/setting/info.png"),
				},
				{
					title: "模式切换",
					id: "mode",
					icon: require("@/assets/img/setting/mode.png"),
				},
				{
					title: "系统通知",
					id: "notify",
					icon: require("@/assets/img/setting/notify.png"),
				},
				{
					title: "语言切换",
					id: "language",
					icon: require("@/assets/img/setting/language.png"),
				},
				{
					title: "账号文件保存位置",
					id: "save",
					icon: require("@/assets/img/setting/save.png"),
				},
				{
					title: "缓存管理",
					id: "cache",
					icon: require("@/assets/img/setting/cache.png"),
				},
				{
					title: "服务协议",
					id: "protocol",
					icon: require("@/assets/img/setting/protocol.png"),
				},
				{
					title: "偏好设置",
					id: "prefer",
					icon: require("@/assets/img/setting/prefer.png"),
				},
			],
			value: 1,
			isNotify: false,
		};
	},
	methods: {},
};
</script>

<style lang="less" scoped>
@import "@/style/mixin.less";
.setting_page {
	padding-left: 40px;
	.top_title {
		padding: 0 40px;
		height: 64px;
		border-bottom: 1px solid #dedfe2;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #374567;
		line-height: 25px;
		.flex(flex-start);
		img {
			margin-right: 8px;
		}
	}
	.ant-anchor-link {
		padding: 19px 40px;
		cursor: pointer;
		.list_top_left {
			.flex(flex-start);
			img {
				margin-right: 6px;
			}
		}
		&:hover {
			background-color: #f5f6f7;
		}
	}
	.page_body {
		.flex(flex-start;flex-start);
		.right_content {
			margin-left: 160px;
			width: 680px;
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #374567;
			line-height: 22px;
			.title {
				margin: 28px 0 16px;
			}
			.setting_list {
				width: 680px;
				padding: 0 24px;
				background: #ffffff;
				border-radius: 4px;
				border: 1px solid #dedfe2;
			}
			.setting_item {
				height: 66px;
				.flex();
				.item_left {
					font-size: 14px;
					.setting_item_title {
						margin-bottom: 4px;
						font-size: 14px;
						color: #374567;
					}
					.setting_item_desc {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #919cad;
					}
				}
				.item_right {
					color: #4c84ff;
				}

				&:not(:last-child) {
					border-bottom: 1px solid #dedfe2;
				}
			}
		}
	}
}
</style>
